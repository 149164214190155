import { RemixBrowser } from '@remix-run/react';
import { startTransition, StrictMode } from 'react';
import { hydrateRoot } from 'react-dom/client';

import 'core-js/proposals/object-from-entries'; // needed for Object.fromEntries in iOS 12.0 (supported in 12.2+)
import { MuiProvider } from '@tbd/lift-ui/mui';

const deferTask =
  typeof requestIdleCallback === 'function' ? requestIdleCallback : setTimeout;

deferTask(() => {
  startTransition(() => {
    hydrateRoot(
      document,
      <StrictMode>
        <MuiProvider>
          <RemixBrowser />
        </MuiProvider>
      </StrictMode>
    );
  });
});
